import { useEffect, useRef, useState } from 'react';
import './style.scss';

const HubSpotForm = ({ portalId, formId, onSubmit }) => {
  const isAlreadySubscribed = () => {
    return JSON.parse(localStorage.getItem(`nnn-cloud-email-notify`) || 'null');
  };
  const formRef = useRef(null);
  const [alreadySubscribed, setAlreadySubscribed] = useState(isAlreadySubscribed());

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: portalId,
          formId: formId,
          target: '#hubspotForm',
          onFormSubmit: event => {
            const formElement = document.querySelector('#hubspotForm form');
            let emailValue = '';
            // Extract values from the form
            const formData = new FormData(formElement);
            for (let [key, value] of formData.entries()) {
              if (key.includes('primary_email')) {
                emailValue = value;
              }
              console.log(`${key}: ${value}`);
            }
            onSubmit(emailValue);
          },
        });
      }
    });

    return () => {
      // Clean up the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, [formId]);

  // if (alreadySubscribed) {
  //   return (
  //     <div className="text-primary tw-text-lg">
  //       You already subscribed using {alreadySubscribed.email} email address!!
  //     </div>
  //   );
  // }
  return <div id="hubspotForm" ref={formRef}></div>;
};

export default HubSpotForm;
